/* Project-specific CSS goes here */

/* :root {
  --COLOR-PRIMARY: #00bdba;
  --COLOR-PRIMARY-0: #04f4f0;
  --COLOR-TXT-VALUE: #95f0ec;
} */

:root {
  --NAV-WIDTH: 215px;
}

a.productLogo { padding: 10px 0 0 12px; opacity: 0.9; }
a.productLogo img { height: 36px; }
.telosLogo { padding-right: 26px; }
.topBar { border-top-width: 0; }

.btn-row {
  text-align: right;
  padding: 9px 10px 9px 10px;
  margin: 12px -12px -12px -12px;
  background: #2223;
  border-top: 2px groove #3a3a3a40;
}
.btn-row > * {
  margin-left: 8px;
}

.loading-indicator {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 18px;
}

.MuiGrid-container { min-width: min-content; }
.MuiGrid-item { min-width: 100%; }
.panel {
  min-width: 100%;
  width: max-content;
}
.login .MuiGrid-item,
.login .panel { min-width: 450px; max-width: 450px; }
.login input { width: 300px; }
.login, .login table { min-width: 100%; }
.login .separator { display: flex; align-items: center; text-align: center; color: var(--COLOR-GREY-1);  }
.login .separator::before, .login .separator::after { content: ''; flex: 1; border-bottom: 1px solid var(--COLOR-GREY-1); }
.login .separator::before { margin-right: 10px; }
.login .separator::after { margin-left: 10px; }
.register .MuiGrid-item,
.register .panel { min-width: 560px; max-width: 560px; }
.register input, .register select { width: 350px; }
.register input[type=password] { width: 315px; }
.register .uic-infoTooltip-btn, .resetpwd .uic-infoTooltip-btn { width: 25px; float: right; margin-top: 3px; }
.resetpwd .MuiGrid-item,
.resetpwd .panel { min-width: 420px; max-width: 420px; }
.resetpwd input { width: 240px; }
.resetpwd input[type=password] { width: 205px; }
.users table.accountsList tr td:nth-child(1) { width: 350px; }
.users table.accountFields tr td:nth-child(1) { width: 200px; }
.users table.accountFields input { width: 300px; }

.account .buttons { text-align: right; }
.account .buttons button { margin-left: 10px; }
.account div.text { text-align: left; }

.system a { text-decoration: none; }
.system .panel-body {
  padding: 28px;
  font-size: 18px;
}

.studios .currentshow select,
.hybrid select,
.hybrid .MuiSlider-root,
.tones select { width: 300px; }

.sip .general select, 
.sip .general input { width: 250px; }

.sip table.servers tr td:nth-child(4) a { text-decoration: none; }

.tones h4,
.backup h4,
.tones .config tr td:nth-child(3),
.info-studios .channels tr td:nth-child(2),
.info-studios .channels tr td:nth-child(3) { text-align: center; }

.config select,
.config input,
.config tr td:nth-child(1),
.shows tr td:nth-child(1),
.sip .server tr td:nth-child(1),
.info-system tr td:nth-child(1),
.info-studios .studio tr td:nth-child(1),
.sip .settings tr td:nth-child(1) { width: 225px; }
.backup tr td:nth-child(1) { width: 350px; }

.info-studios .studio .channels tr td:nth-child(1),
.info-studios .studio .devices tr td:nth-child(1) { width: auto; }

.config hr,
.backup hr,
.info-system hr,
.info-studios .studio hr { margin: 10px 0 15px 0; }

.config .qos tr td:nth-child(2) { width: 50px; text-align: center; }
.config .qos tr td:nth-child(3) i { color: var(--COLOR-WHITE); font-style: normal; margin-left: 6px; }

.backup button { min-width: 200px; }

.info .streams th { vertical-align: bottom; text-align: center; }
.info .streams th:not(:first-child), .info .streams td:not(:first-child) { border-left: 1px solid var(--COLOR-GREY-0); }

.licenses input { width: 250px; }
.licenses .addLicense input  { width: 500px; }
.license-server .addServer input { width: 350px; }
.licenses tr td:nth-child(1),
.license-server tr td:nth-child(1) { width: 200px; }
.licenses table.licensesList,
.licenses table.featuresList,
.license-server table.serversList { width: 100%; }
.licenses table.licensesList button,
.license-server table.serversList button { float: right; }
.licenses table.featuresList tr td:nth-child(2) { width: 200px; }
.licenses table.licensesList hr { margin: 10px 0 20px 0; }
.licenses table.licensesList tr:nth-child(1) hr { display: none; }
.licenses table.licensesList span.warning { color: var(--orange); }
.licenses table.licensesList td:last-child,
.license-server table.serversList td:last-child { padding-right: 0; }

.notification, .notification-grey { text-align: center; font-weight: bold; }
.notification { color: var(--orange); }
.notification-grey { color: var(--COLOR-GREY-2); }
.notification svg { height: 20px; }

ul.breadcrumb, ul.warnings { padding: 10px 16px; list-style: none; margin-bottom: 18px; }
ul.breadcrumb li { display: inline; font-size: 18px; }
ul.breadcrumb li+li:before { padding: 8px; color: var(--COLOR-WHITE); content: "/"; }
ul.breadcrumb li a { color: var(--COLOR-WHITE); text-decoration: none; }
ul.breadcrumb li a:hover { text-decoration: underline; }
ul.warnings { border: 2px solid var(--orange); color: var(--orange); }
ul.warnings li { font-size: 20px; line-height: 28px; }

.uic-iconBtn { padding: 2px; }
.uic-modal-btnGroup .uic-btn { margin-left: 10px; }

.uic-infoTooltip h1 { font-size: 18px; }
.uic-infoTooltip h2 { font-size: 16px; }
.uic-infoTooltip h3 { font-size: 14px; }
.uic-infoTooltip h1,
.uic-infoTooltip h2,
.uic-infoTooltip h3 {
  color: var(--primary-light);
  margin-bottom: 4px;
  border-bottom: 2px solid var(--COLOR-GREY-2);
}
.uic-infoTooltip a { font-weight: bold; }
.uic-infoTooltip p,
.uic-infoTooltip ul,
.uic-infoTooltip ol,
.uic-infoTooltip table { color: #bbbbbb; margin: 4px 0 4px 0; font-size: 16px; }
.uic-infoTooltip ul,
.uic-infoTooltip ol { padding-inline-start: 24px; }
.uic-infoTooltip li>ul,
.uic-infoTooltip li>ol { padding-inline-start: 10px; margin-top: 0; }
.uic-infoTooltip blockquote {
  padding: 0;
  border-top: 1px solid var(--COLOR-GREY-1);
  border-bottom: 1px solid var(--COLOR-GREY-1);
}
.uic-infoTooltip code { font-size: 16px; color: var(--primary-light); }
.uic-infoTooltip pre {
  margin: 4px 0 4px 0;
  padding: 2px 0 2px 0;
  border-top: 1px solid var(--primary-light);
  border-bottom: 1px solid var(--primary-light);
}
.uic-infoTooltip table th { font-weight: bold; }
.uic-infoTooltip table th,
.uic-infoTooltip table td {
  padding: 2px 6px 2px 6px;
  background-color: var(--COLOR-GREY-0);
}

.register-eula p, .register-benefits p,
.register-eula li, .register-benefits li { line-height: 26px; }

.streamPicker { --COLOR-POPOVER: #383838; }
.streamPicker table td { color: var(--text) !important; }
.streamPicker button { float: right; margin-left: 10px; }
.streamPicker input, .streamPicker select { width: 300px; background-color: #303030 !important; }
.streamPicker input:focus, .streamPicker select:focus, .streamPicker button:focus {
  outline: none; box-shadow: 0 0px 0 2px hsla(var(--primary-h), var(--primary-s), calc(var(--primary-l) + 30%), 0.5);
}
.streamPicker .lw_stream { width: 155px !important; }
.streamPicker .lw_source { float: right; }
.streamPicker .lw_source select { width: 135px; }
.streamPicker .errorMessage { color: var(--orange); }
.streamPicker .uic-toggle-slider { background-color: #777 !important; }
.streamPicker .uic-toggleBtns { float: right; }
.streamPicker .uic-toggleBtns button { margin: 0; width: 216px; }